import React from 'react'
import feasibility from '../../../static/Feasibilty.jpg'
export default function Feasibility() {
  return (
    <div>
              <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-[16px] lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Feasibility Study & Due Diligence</h2>
        </div> 
    <div className='bg-berry'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4 my-10 xl:my-20 justify-center items-center appear '>

<div class="thumbanail right  ">
        <img src={feasibility} alt="" data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000"/>
    </div>
<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Feasibility Study & Due Diligence Services</p>
<p class="text-white text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
IMZI  performs Feasibility Study & Due Diligence Services, providing clients with in-depth analyses and insights crucial for informed decision-making in the business landscape. Our dedicated team combines industry expertise with meticulous research methodologies to deliver comprehensive feasibility studies and due diligence reports tailored to your unique business requirements.

</p>
<p class="text-white  text-[16px] my-4"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Our feasibility studies are crafted to assess the viability and potential success of proposed business ventures. Whether entering a new market, launching a product, or embarking on a major project, our analyses provide you with a clear understanding of risks, opportunities, and potential returns on investment.
Market Analysis and Research:


</p>
<p class="text-white  text-[16px] my-4"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Gain a competitive edge with our detailed market analyses. We conduct thorough research to evaluate market dynamics, trends, and competitive landscapes, enabling you to make informed market-entry decisions and strategic business planning.


</p>

</div>
</div>

  </div>
  <div className='bg-berry'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4 my-4 justify-center items-center appear '>

<p class="mb-4 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Financial Feasibility Assessment:</p>

<p class="text-white  mb-4 text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Optimize financial decision-making with our financial feasibility assessments. We analyze projected financial outcomes, assess investment requirements, and provide insights to support prudent financial planning and risk management.

</p>

<p class="mb-4 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Due Diligence Services:</p>

<p class="text-white mb-4 text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Mitigate risks associated with business transactions through our due diligence services. We conduct comprehensive reviews of potential investments, mergers, or acquisitions, providing you with a detailed understanding of the target company's financial health, legal compliance, and operational efficiency.

</p>
<p class="mb-4 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Operational Due Diligence:</p>

<p class="text-white mb-4 text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Ensure operational excellence by leveraging our operational due diligence services. We assess the efficiency and effectiveness of business operations, identifying potential areas for improvement, cost savings, and enhanced operational performance.

</p>

</div>
</div>

  </div>

  </div>
  )
}