import React from 'react'
import './css/controlService.css'
import i1 from '../../../static/i1.jpg'
import i2 from '../../../static/i2.jpg'

import i3 from '../../../static/i3.jpg'
export default function InventionandForensic() {
  return (
    <div className='bg-fruit'>
          <div className='h-24  overflow-x-hidden bg-fruit'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Investigation and Forensic Audit Services

</h2>
        </div> 
 <div class="p-1 items-center justify-center  ">
 <div class="flex justify-center items-center mt-6 my-10 ">
  
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-10 sm:mx-10 md:mx-10 lg:mx-44"data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
     

  <div class=" justify-center items-center w-full max-w-sm bg-white  rounded-lg shadow img-cards">
  <figure class="relative max-w-sm ">
<a href="#">
  <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
  filter blur-0 hover:blur-sm"
   src={i1}
   alt="image description"/>
</a>

</figure>
</div> 
   <div class="hidden sm:block  md:hidden lg:block w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
  <figure class="relative max-w-sm ">
<a href="#">
  <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
  filter blur-0 hover:blur-sm"
  src={i2}
   alt="image description"/>
</a>

</figure>
</div>    
<div class="w-full max-w-sm hidden md:block lg:block  border-fruit rounded-lg shadow img-cards">
  <figure class="relative max-w-sm ">
<a href="#">
  <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
  filter blur-0 hover:blur-sm"
  src={i3}
   alt="image description"/>
</a>

</figure>
</div>


      
  </div>


</div>
<div className='mx-2 my-20 justify-center items-center appear 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">

 
<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start" data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Investigation and Forensic Audit Services</p>
<p class="text-white text-left text-[16px]"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">In an era where business risks and uncertainties abound, IMZI offers specialized Investigation and Forensic Audit services to empower organizations with the tools needed to navigate challenges and protect their interests. Our seasoned team of investigators and forensic auditors are adept at uncovering financial irregularities, fraud, and misconduct, providing you with the insights necessary for informed decision-making and risk mitigation.



</p>
<p class="text-white text-left text-[16px] my-2"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

Our Investigation and Forensic Audit services are focused on identifying and preventing fraud within your organization. We employ sophisticated techniques and methodologies to uncover irregularities, ensuring that your business is equipped to safeguard its assets and reputation.

We conduct meticulous examinations of financial records, transactions, 
and business processes to unearth hidden issues and irregularities. 
</p>
<p class="text-white text-left text-[16px] my-2"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

 Our forensic audit approach is comprehensive, leaving no stone unturned in the pursuit of uncovering the truth. Beyond fraud detection, our services encompass a broader scope of corporate compliance. We evaluate internal controls, governance structures, and adherence to regulatory requirements, ensuring your organization operates with integrity and within legal boundaries.

We understand the sensitive nature of investigations. Our team operates with the
 utmost confidentiality and discretion, ensuring that your organization's 
 reputation is protected throughout the investigative process

</p>
 </div>
 </div>

    </div>
  )
}
