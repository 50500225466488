import React from 'react'
import buisness from '../../../static/business restructuring.jpg'
export default function Buisness() {
  return (
    <div>
    <div className='bg-fruit'>
    <div className='h-24  overflow-x-hidden bg-fruit'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Business process integration</h2>
        </div> 
<div class="p-1 items-center justify-center  ">

<div className='mx-4  justify-center items-center appear '>



<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Business process integration</p>
<p class="mb-3 text-[16px] text-white"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Business process integration (BPI) is the process of connecting different systems, applications, and processes within an organization to enable the smooth flow of information and data between them. This integration is critical for businesses to increase their operational efficiency, reduce costs, and ensure that all parts of their business are working together in harmony.

</p>

<p class="mb-3 text-[16px] text-white  font-medium"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
Steps to achieve BPI:
</p>

<div class="thumbanail right h-72 "data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={buisness} alt="" />
    </div>
<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
<div class="flex flex-col pb-3">

<dd class="text-[16px]  "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Identify the business processes that need to be integrated</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Assess the current state of the business processes </dd>
</div>
<div class="flex flex-col py-3"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

<dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Design the integrated business processes</dd>
</div>
<div class="flex flex-col py-3"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

<dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Implement the integrated business processes</dd>
</div>
<div class="flex flex-col pt-3"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

<dd class="text-[16px] ">Monitor and optimize the integrated business processes</dd>
</div>
</dl>




</div>
</div>

</div>
<div className='bg-fruit'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4  justify-center items-center appear '>




<p class="mb-3 text-[16px] text-white  font-medium "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Benefits of BPI

.</p>

<p class="mb-3 text-[16px] text-white  "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
BPI can offer a number of benefits to businesses, including:
.</p>


<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
<div class="flex flex-col pb-3">

<dd class="text-[16px]  "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Reduced costs</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Improved customer service </dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Enhanced decision-making</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Provide a better customer experience.</dd>
</div>

</dl>

</div>
</div>

</div>
</div>
  )
}
