import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AccountingandBuisness from './AccountingandBuisness';
import Bookeeping from './Bookeeping';
import BuisnuessOutsourcing from './BuisnuessOutsourcing';
import PayrollHr from './PayrollHr';
import ProvidentFund from './ProvidentFund';
import ScrollToTop from '../../ScrollToTop';

export default function AccountingIndex() {
  return (
    
<Routes>
{/* <ScrollToTop/> */}
<Route path="/accountingbuisness" element={<AccountingandBuisness />} />
<Route path="/bookeeping" element={<Bookeeping/>} />
<Route path="/buisnessoutsourcing" element={<BuisnuessOutsourcing />} />
<Route path="/payrollHr" element={<PayrollHr />} />
<Route path="/provident" element={<ProvidentFund />} />

</Routes>
  )
}
