import React from 'react'
import v1 from '../../../static/VAT.jpg'
import v2 from '../../../static/vat2.webp'
import v3 from '../../../static/vat3.avif'
export default function VatConsulting() {
  return (
    <div>
                <div className='h-24  overflow-x-hidden bg-fruit'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">VAT Consulting</h2>
        </div> 
             <div className='bg-fruit'>
 <div class="p-1 items-center justify-center  ">
 <div class="flex justify-center items-center mt-6 my-10 ">
  
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-10 sm:mx-10 md:mx-10 lg:mx-44"data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
     

  <div class=" justify-center items-center w-full max-w-sm bg-white  rounded-lg shadow img-cards">
  <figure class="relative max-w-sm ">
<a href="#">
  <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
  filter blur-0 hover:blur-sm"
   src={v1}/>
</a>

</figure>
</div> 
   <div class="hidden sm:block  md:hidden lg:block w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
  <figure class="relative max-w-sm ">
<a href="#">
  <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
  filter blur-0 hover:blur-sm"
  src={v2}
   alt="image description"/>
</a>

</figure>
</div>    
<div class="w-full max-w-sm hidden md:block lg:block  border-fruit rounded-lg shadow img-cards">
  <figure class="relative max-w-sm ">
<a href="#">
  <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
  filter blur-0 hover:blur-sm"
  src={v3}
   alt="image description"/>
</a>

</figure>
</div>


      
  </div>


</div>
<div className='mx-4 my-20 justify-center items-center appear '>

 
<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> VAT Consulting Services</p>

<p class="mb-3 text-[16px] text-white "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">IMZI offers specialized VAT Consulting Services aimed at helping businesses navigate the complexities of Value Added Tax (VAT) regulations. Our experienced team of VAT consultants provides strategic insights, practical solutions, and compliance support to ensure that your organization optimally manages VAT implications while minimizing risks.

.</p>
<p class="mb-3 text-[16px] text-white"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Stay on top of evolving VAT regulations with our comprehensive compliance assurance services. We assist in the preparation and submission of accurate VAT returns, ensuring that your organization complies with all statutory requirements.</p>

<p class="mb-3 text-[16px] text-white  "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
Our VAT consultants work closely with clients to develop strategic VAT planning tailored to their business objectives. We analyze your business transactions to identify opportunities for VAT optimization, reducing tax liabilities and maximizing cash flow. We also provide services for;
.</p>

<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
    <div class="flex flex-col pb-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">VAT Registration and Deregistration</dd>
    </div>
    <div class="flex flex-col py-3">
        
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">VAT Audits </dd>
    </div>
    <div class="flex flex-col pt-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Training and Knowledge Transfer</dd>
    </div>
</dl>



 </div>
 </div>

    </div>
    </div>
  )
}
