import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OurFlagship from './OurFlagship'
import OurPurpose from './OurPurpose'
import OurTeam from './OurTeam'
import OurVision from './OurVision'
import WhoweAre from './WhoweAre'
import ScrollToTop from '../ScrollToTop';


export default function AboutIndex() {
  return (
    <>
<Routes>
{/* <ScrollToTop/> */}
  <Route path="/ourteam" element={<OurTeam />} />
  <Route path="/ourvision" element={<OurVision />} />
  <Route path="/ourflagship" element={<OurFlagship />} />
  <Route path="/ourpurpose" element={<OurPurpose />} />
  <Route path="/whoweare" element={<WhoweAre />} />
</Routes>
    </>
  )
}
