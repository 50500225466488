import React from 'react'
import p1 from '../../static/p1.webp'
import p2 from '../../static/p2.webp'
import p3 from '../../static/p3.webp'
import p4 from '../../static/p4.webp'
export default function OurPurpose() {
  return (
    <div>
          <div className='h-24 bg-berry overflow-x-hidden ' >
    <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">Purpose statement</h2>
</div> 
<div className='flex flex-col lg:flex-row items-center justify-center bg-berry'>
<div className='w-full lg:w-1/2 my-10 mx-10'>
    <div class="grid grid-col-2 ">
    <div className='mx-10 thumb'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
  <img className="w-full h-auto max-h-96 rounded-lg" 
    src={p2} alt=""/>
</div>

  
    </div>
  </div>
  <div className='w-4.6 lg:w-1/2 my-10 mx-12 lg:mx-28'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">

    <p className=' text-[16px]  text-white text-reveal'>
    We empower our people and clients to interpret numbers in the language of business and sustainability.
    </p>
  </div>
</div>
    </div>
  )
}
