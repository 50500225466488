import React from 'react';
import art1 from '../../static/newwsroom1.jpg'; // Ensure the path is correct
import art2 from '../../static/masud-khan-article.png';

export default function NewsRoom() {
  return (
    <div>
      {/* Header */}
      <div className='h-24 bg-berry'>
        <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl'>NewsRoom</h2>
      </div>

      <div class="p-1 flex flex-wrap items-center justify-center  ">
        <div class="flex justify-center items-center my-10 mx-10">

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-10">

<div data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a 
    href="https://www.london.edu/news/lbs-open-executive-education-in-global-top-three?_cldee=MOo6zRXbfrAr8JdjesyDDmRgoJa3ZvXFHJutM_MD7VR8lLv99hlclk1qH4bjuikh&recipientid=contact-cc24df410808ed1196a80022483f942a-6def2397478b4b709486a2b05b5cc4c1&utm_source=ClickDimensions&utm_medium=email&utm_campaign=MC_BRBRAND_email_ClickDimensions_CIA&esid=8ddd780c-2214-ef11-96c1-0022483f942a" 
    target="_blank" 
    rel="noopener noreferrer" 
  className=''>
    <img 
    
    class="rounded-lg  cursor-pointer h-96 w-96
   "
     src={art1}
     alt="image description"/>
  </a>
 
</figure>
</div>
</div>
<div data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

<div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
 <figure class="relative max-w-sm ">
<a     href="https://www.tbsnews.net/features/pursuit/6-things-i-learned-my-career-ca-masud-khan-967531" 
            target="_blank" 
            rel="noopener noreferrer" 
className=''>
 <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
"
src={art2}
  alt="image description"/>
</a>

</figure>
</div>
</div>     


    
    </div>
</div>


        </div>
    </div>
  );
}
