import React from 'react'
// import pay1 from '../../../static/pay1.webp'
// import pay2 from '../../../static/pay2.webp'
// import pay3 from '../../../static/pay3.webp'
import pay4 from '../../../static/payrolhr.avif'
export default function PayrollHr() {

  return (
    <div>
   
        <div className='h-24  overflow-x-hidden bg-fruit'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Payroll / HR Processing Services</h2>
        </div> 


        <div className='flex flex-col lg:flex-row items-center justify-center bg-fruit'>
  <div className='w-full lg:w-1/2 my-10 mx-10'>
    <div class="grid grid-col-2 ">
    <div className='mx-10 thumb'data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
  <img className="w-full h-auto max-h-96 rounded-lg" 
    src={pay4} alt=""/>
</div>

  
    </div>
  </div>
  <div className='w-4.6 lg:w-1/2 my-10 mx-12 lg:mx-10 justify-end align-middle items-center appear'>
    
  <p className='mb-3 text-white text-[16px]
first-line:tracking-widest first-letter:text-4xl first-letter:font-bold
 first-letter:text-white
 first-letter:me-1 first-letter:float-start'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    IMZI
     takes the complexity out of Payroll and HR Processing, offering comprehensive services that ensure compliance, 
     and enhance overall operational efficiency. 

 </p>
 <p className='text-left text-white text-[16px]'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
 Our dedicated team combines industry expertise with cutting-edge technology to deliver accurate, timely, and tailored solutions that empower your organization to focus on its core objectives. Trust our experts to handle your payroll processing with precision. From salary calculations to tax withholdings, we ensure accurate and timely disbursement, minimizing errors and providing your employees with the confidence that they are paid correctly and on time.

Stay ahead of evolving tax regulations with our expert tax compliance services. 

 </p>
 <p className='text-left text-white text-[16px] my-2'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
  
We manage tax withholdings, filings, and reporting, ensuring that your organization remains in compliance with local, state, and federal tax requirements.
 </p>
  </div>
</div>
    </div>
  )
}
