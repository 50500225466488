import React from 'react'
import './css/Resouces.css'
import { Link } from 'react-router-dom'
import res1 from '../../static/in1.avif'
import res2 from '../../static/res2.webp'
import res3 from '../../static/res3.webp'
export default function Resouces() {
  return (
  <>
<div className='bg-cyan border'>
<div className='h-24 overflow-x-hidden'data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">

  

<h2 className='text-center my-4 lg:text-3xl text-xl overline text-white'>Resources</h2>
</div> 
<div className="resouce "data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">
    <div className="r-container">
    <Link  to='/insights'>
        <div className="r-box mx-3">
         <div className="img-box">
      
            <img src={res1} alt="" srcset="" />
         </div>
         <div className="layer layer1"></div>
         <div className="layer layer2"></div>
         <div className="r-contentbx">
            <h6 className=' text-white text-center mt-48
             text-xl
              bg-black opacity-50 px-44 py-6'>Insights</h6>
         </div>
        </div>   
        </Link>
        <Link to='/newsroom' >
        <div className="r-box mx-3">
         <div className="img-box">
      
            <img src={res2} alt="" srcset="" />
         </div>
         <div className="layer layer1"></div>
         <div className="layer layer2"></div>
         <div className="r-contentbx">
            <h6 className=' text-white text-center mt-48  
            text-xl
             bg-black opacity-50 px-44 py-6'>Newsroom</h6>
         </div>
        </div>   
        </Link>
        <Link to='/library' >
        <div className="r-box mx-3">
         <div className="img-box">
      
            <img src={res3} alt="" srcset="" />
         </div>
         <div className="layer layer1"></div>
         <div className="layer layer2"></div>
         <div className="r-contentbx">
            <h6 className=' text-white text-center mt-48  
            
            text-xl bg-black opacity-50 px-44 py-6 '>Library</h6>
         </div>
        </div>   
        </Link>
    </div>
</div>

</div>
  </>
  )
}
