import React from 'react'
import './css/controlService.css'
import c1 from '../../../static/c1.webp'
import c2 from '../../../static/c2.webp'
import c3 from '../../../static/c3.webp'
import c4 from '../../../static/c4.webp'
export default function ControlService() {
  return (
    <>
                <div className='h-24  overflow-x-hidden bg-cyan'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Internal Control System Design & Development</h2>
        </div> 
<div className='flex flex-col lg:flex-row items-center justify-center bg-cyan'>
<div className='w-full lg:w-1/2 my-10 mx-10'>
    <div class="grid grid-col-2 ">
    <div className='mx-10 thumb'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
  <img className="w-full h-auto max-h-96 rounded-lg" 
    src={c3} alt=""/>
</div>

  
    </div>
  </div>
  <div className='w-4.6 lg:w-1/2 my-10 mx-12 lg:mx-10'>
    <h1 className='mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start'data-aos="zoom-in-down"data-aos-delay="50"
 data-aos-duration="1000">Internal Control System Design & Development</h1>
    <p className=' text-[16px] text-left text-white appear'data-aos="zoom-in-down"data-aos-delay="50"
 data-aos-duration="1000">At IMZI  Advisory,
     we specialize in crafting robust Internal Control Systems tailored 
     to fortify the foundation of your organization. Our dedicated team of 
     experts collaborates closely with your business to design and develop 
     customized control frameworks that enhance operational efficiency, mitigate risks, 
     and ensure compliance with industry regulations.



    </p>
    <p className=' text-[16px] text-left text-white appear my-2'data-aos="zoom-in-down"data-aos-delay="50"
 data-aos-duration="1000">

Our approach to Internal Control System design is not one-size-fits-all.
 We meticulously analyze your organization's unique structure,
  processes, and risk landscape to create bespoke control solutions 
  that align with your strategic objectives. Identifying and mitigating risks
   is at the core of our design process. We work proactively
    to implement controls that safeguard against potential threats,
     ensuring the integrity of financial reporting and protecting your organization's assets.



    </p>
    <p className=' text-[16px] text-left text-white appear my-2'data-aos="zoom-in-down"data-aos-delay="50"
 data-aos-duration="1000">



Our experts stay abreast of industry standards and legal mandates, incorporating them seamlessly into the design of your Internal Control System to keep your organization compliant. We believe in the evolution of internal controls in tandem with your organization's growth. Our services extend beyond the initial design phase, encompassing ongoing monitoring, evaluation, and refinement to ensure that your control system adapts to changing business dynamics.

    </p>
  </div>
</div>
    </>
  )
}
