import React from 'react'
import a from '../../../static/enterprise.webp'
export default function Enterprise() {
  return (
    
    <div class="c-container bg-cyan">
    <div className='h-24  overflow-x-hidden bg-cyan'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Enterprise Risk Management</h2>
        </div> 
    <div className="my-4 appear ">
   
    
    <p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-ip"data-aos-delay="50"
 data-aos-duration="1000"> Enterprise Risk Management Services</p>

    <p className='text-[16px]'data-aos="zoom-in-ip"data-aos-delay="50"
 data-aos-duration="1000">
   
    Your organization faces a seemingly endless list of challenges — 
    from targeted attacks, to internal threats, shifting regulations,
     and socioeconomic factors beyond your control. It’s critical to 
     anticipate these issues before they arise and have clear plans to
      inform your decision making when they do.  


    </p>
    <p className=' text-[16px]my-2'data-aos="zoom-in-ip"data-aos-delay="50"
 data-aos-duration="1000">
   
    Our Enterprise Risk Services help you identify and address
     threats across all areas of your organization. 
      we have the experience and expertise to implement 
      risk mitigation strategies that position you for 
      long-term success. We’re committed to helping you 
      protect your people, your reputation, and your bottom line.
       Enterprise risk management (ERM) is a holistic approach to
        managing risks across an organization. 


   </p>
   <p className=' text-[16px] my-3'data-aos="zoom-in-ip"data-aos-delay="50"
 data-aos-duration="1000">
   Overall, by identifying and mitigating risks,
    integrating sustainability into strategy and decision-making,
     improving operational efficiency, and enhancing reputation,
      ERM can help organizations to become more sustainable and achieve 
      their long-term goals. ERM can help organizations to become more 
      sustainable in a number of ways: 


  </p>
  <div class="thumbanail right         "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={a} alt="" />
    </div>
  <dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
    <div class="flex flex-col pb-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">	By identifying and mitigating sustainability risks</dd>
    </div>
    <div class="flex flex-col py-3">
        
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">By integrating sustainability into the organization's strategy and decision-making. </dd>
    </div>
    <div class="flex flex-col pt-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">By improving operational efficiency. </dd>
    </div>
    <div class="flex flex-col pt-3">
       
       <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">By enhancing reputation</dd>
   </div>


</dl>

    
    <p className=' text-[16px] my-3'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    Our comprehensive ERM approach combines industry expertise with cutting-edge methodologies, providing clients with strategic insights and practical solutions to  safeguard their operations and enhance long-term resilience. To ensure sustainable ERM process in your organization,  IMZI follows COSO framework.


  </p>
      </div>

      
</div>
  )
}
