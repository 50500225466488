import React from 'react';

import o1 from '../../static/lifeat1.jpg';
import o2 from '../../static/lifeat2.jpg';
import o3 from '../../static/lifeat3.jpg';
import o4 from '../../static/lifeat4.jpg';
import o5 from '../../static/o2.jpg';
import './css/lifeatimzi.css';

export default function LfieImzi() {
  return (
    <div>
      <div className='h-28 bg-cyan overflow-x-hidden'>
        <h2
          className='text-center my-7 overline text-xl lg:text-3xl text-white'
          data-aos='zoom-in'
          data-aos-delay='50'
          data-aos-duration='1000'
        >
          IMZI Office Culture and Environment
        </h2>
      </div>

      <div className='bg-cyan'>
        <div className='grid grid-cols-2
        
        
         sm:grid-cols-2 md:grid-cols-2 gap-4 mx-auto max-w-5xl'>
          <div
            className='my-2 '
            data-aos='zoom-in'
            data-aos-delay='50'
            data-aos-duration='1000'
          >
            <img className='h-auto rounded-lg' src={o5} alt='' />
          </div>
          <div
            className='my-2 '
            data-aos='zoom-in'
            data-aos-delay='40'
            data-aos-duration='800'
          >
            <img className='h-auto rounded-lg' src={o2} alt='' />
          </div>
          <div
            className='my-2 '
            data-aos='zoom-in'
            data-aos-delay='40'
            data-aos-duration='800'
          >
            <img className='h-auto rounded-lg' src={o3} alt='' />
          </div>
          <div
            className='my-2 '
            data-aos='zoom-in'
            data-aos-delay='40'
            data-aos-duration='800'
          >
            <img className='h-auto rounded-lg' src={o4} alt='' />
          </div>
          <div
            className='my-2 flex justify-center col-span-2'
            data-aos='zoom-in'
            data-aos-delay='20'
            data-aos-duration='400'
          >
            <img className=' h-36 sm:h-80 md:h-96 rounded-lg' src={o1} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}
