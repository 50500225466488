import React, { useState } from "react";
import ReadMore from '../homepage/ReadMore'
import p1 from '../../static/masud2.jpg'
import p2 from '../../static/zia2.jpg'
import p3 from '../../static/iman3.jpg'
import p4 from '../../static/kaiser1.jpg'
import p5 from '../../static/default-pic.jpeg'
import './css/OurTeam.css'
export default function OurTeam() {
  const email1 = "masud@imzi-ca.com";
  const email2 = "ziaul@imzi-ca.com";
  const email3 = "iman@imzi-ca.com";
  const [isShowMore, setIsShowMore] = useState(false);
  const [isShowMore2, setIsShowMore2] = useState(false);
  const [isShowMore3, setIsShowMore3] = useState(false);
  const [isShowMore4, setIsShowMore4] = useState(false);
  const [isShowMore5, setIsShowMore5] = useState(false);
  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  const toggleReadMoreLess2 = () => {
    setIsShowMore2(!isShowMore2);
  };
  const toggleReadMoreLess3 = () => {
    setIsShowMore3(!isShowMore3);
  };
  const toggleReadMoreLess4 = () => {
    setIsShowMore4(!isShowMore4);
  };
  const toggleReadMoreLess5 = () => {
    setIsShowMore5(!isShowMore5);
  };
  return (
    <>

      <div className="bg-berry h-auto ">

        <div className='h-24 bg-berry overflow-x-hidden'>
          <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl ' data-aos="zoom-in" data-aos-delay="50"
            data-aos-duration="1000">Partner’s Profile</h2>
        </div>

        <div class="c-container bg-berry ">

          <div className=" h-5/6  sm:mx-24 md:mx-32 lg:mx-20 ">

            <img src={p1} alt="" data-aos="zoom-in" data-aos-delay="50"
              data-aos-duration="1000"
              className=" float-right  mx-2 
       rounded-lg h-96 w-96"/>



            <h4 className='text-orange-100  animate-pulse text-3xl' data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine">Masud Khan FCA, FCMA</h4>


            <p className=' text-[16px]  mb-4' data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine">
              Partner

            </p>

            <div data-aos="fade-up"
              data-aos-offset="300">
              <p className=' text-[16px]'>


                With a distinguished career spanning over four decades, Masud Khan has held pivotal roles in leading organizations, contributing significantly to their growth and success.

              </p>
              <p className=" text-[16px]">As the Group Chief Executive Officer / Chief Adviser of the Board at Crown Cement PLC, a large listed local corporate, since November 2017, Mr Khan has spearheaded transformative initiatives in sales and marketing and human resources, implementing internal control policies and achieving substantial cost reductions. He is also an Advisor of Edge Asset Management and Director of Edge Research and Consulting.</p>
              {isShowMore && (
                <div>

                  <p className=" text-[16px]">As the Chief Financial Officer at
                    LafargeHolcim Bangladesh Limited for almost two decades, Mr Khan played
                    a key role in the successful merger of Lafarge with Holcim in Bangladesh.
                    Notably, he led the company's listing on stock exchanges and navigated complex
                    financial negotiations with multilateral lenders in raising more than 200 million
                    USD loan and equity, obtained approval from Bangladesh Bank for setting up a subsidiary
                    company in India, the first of its kind in Bangladesh, setting up business policies and
                    process and ERP, and was actively engaged in managing the business as the second man to
                    CEO including Acting Chief Executive Officer for long periods
                    in the absence of the CEO.</p>
                  <p className=" text-[16px]">
                    During his tenure at British American Tobacco, Bangladesh, Mr Khan served in various capacities, including Chief Accountant, Audit Manager and MRPII Programme Manager. He played a crucial role in setting up innovative inventory systems, tax planning, designing business reporting and ushering business efficiency in all areas of operations. He spearheaded the first ever ERP in Bangladesh in the year 1987.
                  </p>
                  <p className=" text-[16px]">
                    A seasoned corporate leader, Mr Khan has demonstrated exemplary management skills, achieving accolades for negotiating significant tax reductions and successfully implementing ERP systems. He has been a driving force in strategic planning, financial optimization, and talent management.
                  </p>
                  <p className=" text-[16px]"
                  >Mr Khan is a Fellow Member (FCA) of the Institute of Chartered Accountants of India. He is also a Fellow Member (FCMA) of the Institute of Cost and Management Accountants of India and holds a fellowship from CMA (Australia and NZ). He stood 2nd at all India Level in the Intermediate Exams and 23rd at the final exams of the Institute of Chartered Accountants of India</p>

                  <p className="text-[16px]"
                  >Mr Khan stands as a beacon of professional accomplishment, holding a Bachelor of Commerce with Honours in Accounting from the University of Kolkata, India. Beyond his corporate roles, Mr Khan is actively engaged in the business community. He serves as the Chairman of Unilever Consumer Care Bangladesh and as an independent director of Singer Bangladesh and Community Bank. He is a respected figure in academia, serving as a guest lecturer at the Institute of Chartered Accountants of Bangladesh, contributing articles to professional publications and public speaking in electronic media, professional and educational circles and various business and social forums.</p>

                  <p className=" text-[16px]"
                  >With a visionary approach and a commitment to excellence, Mr Khan has left an indelible mark on every organization he has been a part of, shaping financial and business strategies, fostering innovation, and contributing to the professional development of others.</p>
                </div>
              )}
              <button onClick={toggleReadMoreLess} className="text-fruit text-[16px]">
                {isShowMore ? "Read Less" : "Read More"}
              </button>
              <div class="flex mt-4 space-x-5  md:mt-0">
                {/* <a target="_blank"href={`mailto:${email1}`}class="text-fruit hover:text-cyan animate-pulse
             
             ">
                        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                  </svg>
                  <span class="sr-only">Facebook page</span>
              </a> */}

                <a target="_blank" href="https://www.linkedin.com/in/masud-khan-09bb7314/" class="text-fruit
               hover:text-cyan animate-pulse
               
               ">
                  <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                  </svg>
                  <span class="sr-only">GitHub account</span>
                </a>

              </div>
            </div>

          </div>

          <div className=" h-5/6 my-16 sm:mx-24 md:mx-32 lg:mx-20 ">
            <img src={p2} alt="" data-aos="zoom-in" data-aos-delay="50"
              data-aos-duration="1000"
              className="float-left mx-2 rounded-lg h-96  w-96 ppear" />

            <h4 className='text-orange-100 
     animate-pulse text-3xl'data-aos="fade-left"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine">Md Ziaul Alam, FCA, ACA, (ICAEW), ADP (LBS, UK)
            </h4>
            <p className=' text-[16px] mb-4' data-aos="fade-left"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine">
              Managing Partner



            </p>
            <div data-aos="fade-up"
              data-aos-offset="300">		<p className=' text-[16px]'>


                Ziaul, a distinguished Fellow member
                of the Institute of Chartered Accountants
                of Bangladesh (ICAB), is a founding member
                and audit & tax partner at IMZI & IHCA Chartered
                Accountants and IMZI & IHCA Advisory Firm.
                Renowned for his tailored and timely
                solutions, Ziaul excels in providing
                value-driven strategies that optimize
                clients' businesses for maximum tax
                efficiency and aid in achieving their
                organizational and personal goals.



              </p>

              {isShowMore2 && (
                <div>
                  <p className=" text-[16px]">His
                    expertise extends across various domains,
                    including regulatory review, business setup,
                    and due diligence, offering comprehensive
                    advisory services.</p>
                  <p className="text-[16px]">
                    As an assurance specialist,
                    Ziaul prioritizes nurturing
                    robust client relationships at
                    every stage of their business journey,
                    be it establishment, growth, or transition.
                    He takes immense pride in educating and guiding
                    clients through intricate tax laws, compliance
                    , and reporting requirements, ensuring they feel
                    confident in their decisions.
                  </p>
                  <p className=" text-[16px]">Ziaul's strength lies in problem-solving and innovatively addressing his clients' unique challenges while maintaining strict compliance. His expertise spans a wide spectrum of financial disciplines, encompassing Financial Management, Financial Planning & Analysis, Strategic Business Insight, Business Transformation, Organization Restructuring, Credit Management, Enterprise Risk Management, and Risk-Based Internal Audit.</p>
                  <p className="text-[16px]">
                    Throughout his career, Ziaul has held pivotal financial positions
                    at esteemed local and international organizations, such as Japan Tobacco International(JTI), Crown Cement Group, IDCOL (Infrastructure Development Company Limited), BRAC Bank, Grameen Phone, Bhatia Contracting LLC (Dubai), and TwinMos Technologies Incorporation (Dubai).
                  </p>
                  <p className=" text-[16px]">
                    His academic achievements include earning a Master of Commerce
                    and Bachelor of Commerce (Hons.) degree from Jagannath College
                    (now Jagannath University), where he received a Gold Medal for
                    securing the first-class first position in the merit list, majoring in
                    accounting. Additionally, Ziaul underwent an Accelerated Development Program at the London Business School (LBS), UK, further enhancing his leadership and management skills.


                  </p>

                  <p className=" text-[16px]">Ziaul's commitment to delivering exceptional service,
                    paired with his extensive expertise, positions him as a valuable asset to
                    IMZI & IHCA Advisory, where he continually enriches the firm's offerings and ensures
                    clients receive unparalleled financial guidance and solutions.</p>


                </div>
              )}
              <button onClick={toggleReadMoreLess2} className="text-fruit text-[16px]">
                {isShowMore2 ? "Read Less" : "Read More"}
              </button>
              <div class="flex mt-4 space-x-5  md:mt-0">
                {/* <a target="_blank"href={`mailto:${email2}`}class="text-fruit hover:text-cyan animate-pulse
             
             ">
                        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                  </svg>
                  <span class="sr-only">Facebook page</span>
              </a> */}


                <a href="https://www.linkedin.com/in/ziaul-alam-fca-20371a19/" target="_blank" class="text-fruit
               hover:text-cyan animate-pulse
               
               ">
                  <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                  </svg>
                  <span class="sr-only">GitHub account</span>
                </a>

              </div></div>

          </div>
          <div className=" h-5/6 my-36 sm:mx-24 md:mx-32 lg:mx-20 ">

            <img src={p3} alt="" data-aos="zoom-in" data-aos-delay="50"
              data-aos-duration="1000"
              className="float-right mx-2 rounded-lg h-96  w-96 appear " />


            <h4 className='text-orange-100 data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine"
      animate-pulse text-3xl'>Md Hasan Habib Iman, FCA, MBA (IBA, DU)</h4>
            <p className=' text-[16px]  mb-4' data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine">
              Partner



            </p>
            <div data-aos="fade-up"
              data-aos-offset="300">
              <p className=' text-[16px]'>


                Iman, a distinguished Fellow member of the Institute of
                Chartered Accountants of Bangladesh (ICAB), complemented
                by a master's degree from the Institute of Business
                Administration at the University of Dhaka.
                His career is marked by a rich tapestry of
                experiences spanning over 15 years, showcasing
                exceptional proficiency in finance and accounting
                , steering organizations towards excellence with unwavering resolve.

              </p>

              {isShowMore3 && (
                <div>
                  <p className="text-[16px]">With an entrepreneurial spirit, he co-founded IMZI & IHCA Chartered Accountants and IMZI & IHCA Advisory Firm, laying the groundwork for their successful establishment. His journey is defined by pioneering digital transformations, optimizing asset liability management, and architecting sophisticated investment strategies. His expertise extends to the development and implementation of meticulous operating procedures, ensuring precision in financial systems and unwavering compliance with regulatory authorities.</p>
                  <p className="text-[16px]">
                    In the realm of Internal Audit & Forensic Services,
                    Mr. Iman has consistently ensured businesses
                    are well-prepared to navigate complex financial
                    landscapes while mitigating risks. His adept
                    handling of Taxation, VAT & Legal Services,
                    along with RJSC & Company matters related Services
                    , underscores his commitment to keeping clients
                    in regulatory compliance.
                  </p>
                  <p className=" text-[16px]">
                    A pivotal force in the listing of various securities in Stock Exchanges through IPOs and other means, his Due Diligence expertise guarantees transparent and efficient financial processes. His proficiency in conducting Feasibility Studies empowers businesses to make well-informed decisions.
                  </p>
                  <p className="text-[16px]">Throughout his illustrious career, Mr. Iman has held influential financial positions such as CFO, Head of Internal Control & Compliance, and Company Secretary at esteemed local and international organizations, including Central Counterparty BD, UCB Capital, Pragati Life Insurance, OMC Group, BRACNet, and Link3.</p>

                  <p className=" text-[16px]">Mr. Iman also extends his expertise globally by providing International System Audit & Consultancy Services, enabling businesses to align with international standards and best practices. His unwavering commitment to excellence positions him to contribute his extensive experience and comprehensive skill set to IIMZI & IHCA Advisory, enriching their service portfolio and ensuring clients receive unparalleled financial guidance and solutions.</p>


                </div>
              )}
              <button onClick={toggleReadMoreLess3} className="text-fruit text-[16px]">
                {isShowMore3 ? "Read Less" : "Read More"}
              </button>
              <div class="flex mt-4 space-x-5  md:mt-0">
                {/* <a target="_blank"href={`mailto:${email3}`}class="text-fruit hover:text-cyan animate-pulse
             
             ">
                        <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                  </svg>
                  <span class="sr-only">Facebook page</span>
              </a> */}


                <a href="https://www.linkedin.com/in/md-hasan-habib-iman-fca-85419627/" target="_blank" class="text-fruit
               hover:text-cyan animate-pulse
               
               ">
                  <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4" />
                  </svg>
                  <span class="sr-only">GitHub account</span>
                </a>

              </div>
            </div>


          </div>
          <div id="consultant">
            {/* Header */}
            <div className='h-24 bg-berry overflow-x-hidden'>
              <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl' data-aos="zoom-in" data-aos-delay="50" data-aos-duration="1000">
                Consultant Profiles
              </h2>
            </div>

            {/* Consultant Profiles */}
            <div className="flex flex-wrap justify-center p-4">
              {/* Consultant 1 */}
              <div className="flex flex-col items-center w-full sm:w-1/2 lg:w-1/3 p-4">
                <img src={p4} alt="Profile 1" className="mx-2 rounded-full h-72 w-72" data-aos="zoom-in" data-aos-delay="50" />
                <h4 className='text-orange-100 text-center animate-pulse text-3xl' data-aos="zoom-in" data-aos-offset="300">
                  Mr. A B M Kaiser
                </h4>
                <p className='text-[16px] text-center mb-4' data-aos="zoom-in" data-aos-offset="300">
                  Consultant
                </p>
                <div data-aos="zoom-in" data-aos-offset="300">
                  {isShowMore4 && (
                    <div>
                      <div>

                        <p className=" text-[16px]">Mr. A B M Kaiser is a distinguished figure in the business world, serving as one of the Directors of Express Insurance Limited and holding the prestigious position of Chairman of the Company's Claim Committee. Born into a well-known and respected Muslim family, his upbringing has instilled in him a strong sense of values and ethics that reflect in both his professional and personal life..</p>
                        <p className=" text-[16px]">
                          In his illustrious business career, Mr. Kaiser has held key leadership roles, notably as the Chairman and Managing Director of M/s. Gumti Textiles Limited and Gumti Apparels Limited. His influence extends beyond the corporate sphere, having served as a former Director of the Bangladesh Indenting Association.
                        </p>
                        <p className=" text-[16px]">
                          With an extensive background in the textile industry, Mr. Kaiser brings a wealth of experience to the table. His proven track record of success is a testament to his strong leadership and management skills. Throughout his career, he has demonstrated a keen understanding of business management, textile manufacturing, and strategic planning.
                        </p>
                        <p className=" text-[16px]">Presently, Mr. A B M Kaiser is at the helm of his own business venture, M/s. Kaiser Trading Company. As an entrepreneur, he continues to contribute to the business landscape, combining his industry expertise with a forward-thinking approach</p>

                        <p className="text-[16px]">Known for his sincerity and unwavering work ethic, Mr. Kaiser is a dedicated professional who approaches every task with diligence and commitment. Beyond his corporate pursuits, he actively engages in social and charitable activities, showcasing a deep sense of responsibility towards the community.</p>

                        <p className=" text-[16px]">Mr. Kaiser's commitment to social and charitable causes is reflected in his active involvement in various initiatives. His dedication to making a positive impact on society underscores his belief in the importance of corporate social responsibility. His skills and expertise extend to Business Management,
                          Textile Manufacturing, Leadership, Strategic Planning etc.</p>

                        <p className=" text-[16px]">In summary, Mr. A B M Kaiser is a highly accomplished businessman with a rich background in the textile industry. His leadership skills, coupled with a commitment to social responsibility, make him a valuable asset to any organization. As a Consultant at IMZI Advisory, Mr. Kaiser's wealth of experience and diverse skill set contribute to the firm's success and reputation.</p>
                      </div>

                    </div>
                  )}
                  <button onClick={toggleReadMoreLess4} className="flex text-fruit text-[16px] items-center justify-center mx-auto">
                    {isShowMore4 ? "Read Less" : "Read More"}
                  </button>
                </div>
              </div>

              {/* Consultant 2 */}
              <div className="flex flex-col items-center w-full sm:w-1/2 lg:w-1/3 p-4">
                <img src={p5} alt="Profile 2" className="mx-2 rounded-full h-72 w-72" data-aos="zoom-in" data-aos-delay="50" />
                <h4 className='text-orange-100 text-center animate-pulse text-3xl' data-aos="zoom-in" data-aos-offset="300">
                  A I KHAN
                </h4>
                <p className='text-[16px] text-center mb-4' data-aos="zoom-in" data-aos-offset="300">
                  Consultant
                </p>
                <div data-aos="zoom-in" data-aos-offset="300">
                  {isShowMore5 && (
                    <div>
                      <div>

                        <p className=" text-[16px]">Mr. Khan is an accomplished Corporate Finance Management professional with over 15 years of senior leadership experience with Fortune 500 companies, AAA-rated European Development Banks, and award-winning top manufacturing firms. They have successfully closed over $1Bn in corporate finance transactions over the past 8 years, demonstrating a strong ability to navigate complex financial landscapes. Holding a Bachelor’s degree in Business Administration from Portland State University, USA, along with certifications in Moody’s Credit Risk, Digital Banking, Sukuk, Islamic Banking, and Digital Marketing, they bring a well-rounded expertise in both traditional and modern financial practices.</p>
                        <p className=" text-[16px]">
                          Mr. Khan’s core competencies include foreign currency loan arrangements, structured finance, due diligence for international transactions, loan syndications, bond and preference share structuring, security documentation, and risk management. Their specialized skills in currency hedge management, interest rate risk management, and cash flow modeling ensure optimal financial strategy and risk mitigation. Currently, they lead a transaction team managing long-term loans totaling $80 million, focusing on commercial, environmental, social, and sustainability due diligence with Development Financial Institutions (DFIs).
                        </p>
                        <p className=" text-[16px]">
                          Managing a treasury portfolio of over TK 40Bn, including $170 million in debt, they implement sophisticated FX hedge management and cash flow planning strategies. Their expertise extends to developing and implementing robust systems for cash management, foreign currency transactions, loan management, and security documentation. As a leader, they are committed to guiding and coaching finance teams, enhancing operational efficiency, and driving performance to meet strategic business targets.
                        </p>
                        <p className=" text-[16px]">In addition to their technical proficiency, Mr. Khan is highly skilled in building and executing financial strategies for a wide range of industries, including manufacturing, infrastructure, financial services, public sector institutions, and NGOs. They provide value-added services such as arranging complex debt structures, coordinating due diligence processes, and leading financial restructuring efforts. Whether negotiating local and foreign currency obligations or managing large-scale financing arrangements, they consistently deliver results that support business growth and financial stability</p>

                        <p className="text-[16px]">With specialized expertise in the manufacturing sector, they can establish dynamic finance and treasury operations, negotiate favorable funding lines, manage debt and foreign currency transactions, and reduce financial risks. In the financial services sector, they focus on developing credit risk management guidelines, training teams on financial risk assessment, and improving credit risk identification and mitigation strategies.</p>

                        <p className=" text-[16px]"> <span className="font-semibold">CAREER PROFILE — </span>Experienced Corporate Finance Management Professional, 15 years senior leadership roles, including Fortune 500-company, AAA rated European Development Bank and award- winning top manufacturing company. Closed $1Bn Corporate Finance transactions in last 8 years. Bachelors in Business Administration from Portland State University, USA. Certifications: Moody's Credit Risk, Digital Banking, Digital Marketing, Sukuk, Islamic Banking.</p>
                        <p className=" text-[16px]"> <span className="font-semibold">CORE COMPETENCIES — </span>Corporate Finance Management, Foreign Currency Loan Arrangement, Due Diligence for International Transactions, Structured Finance, Loan Syndications, Bond Structuring, Preference Share Structuring, Security Documentation, Currency Hedge Management, Interest Rate Risk Management, Cash Flow Modelling, Bank and Financial Institution Relationship Management (local and international), Business Strategy Planning, Communication, People Management.</p>
                        <p className=" text-[16px] font-bold">SKILLS</p>
                        <ul className="list-disc px-6">
                          <li className="text-[16px] text-white">Arrange and negotiate foreign currency and local currency transactions with international and local financial institutions.
                            Currently leading transaction team in Commercial, Environmental, Social and Sustainability related Due Diligence processes totaling $80million long term loan
                            contracts with Development Financial Institutions (DFIs).</li>
                          <li className="text-[16px] text-white">Lead and Coach Finance and Treasury teams. Currently managing TK40Bn including +$170 million Debt. Implementing FX hedge
                            management, cash management and documentary credit management strategies. Develop and Monitor cash flow models and processes to minimize currency and interest
                            rate risks with efficient cash flow planning and execution.</li>
                          <li className="text-[16px] text-white">	Develop and Implement systems and processes for Cash Management, Foreign Currency transactions, Import Letters of Credit, Local and Foreign Currency Loan Management, Security and Documentation Management.
                            Lead Treasury team in cash collection and Vendor payments.</li>
                          <li className="text-[16px] text-white">	Develop and Implement systems and processes for Cash Management, Foreign Currency transactions, Import Letters of Credit, Local and Foreign Currency Loan Management, Security and Documentation Management.
                            Lead Treasury team in cash collection and Vendor payments.</li>
                          <li className="text-[16px] text-white">	Guide and Coach staff to increase overall efficiency and performance in Financial Management.</li>
                        </ul>

                        <p className=" text-[16px] font-bold my-3">Value Added Services:</p>
                        <ul className="list-disc px-6">
                          <li className="text-[16px] text-white">Build and Execute Finance Strategy for Manufacturing, Infrastructure, Financial Services, Public Sector Institutions and Non-Government Organizations. Organize Finance and Treasury teams to manage Financing of Local and Foreign Currency obligations.</li>
                          <li className="text-[16px] text-white">Assign KPI and business process targets for Finance and Treasury teams. Execute by guiding team to meet Business Strategy to achieve KPI.</li>
                          <li className="text-[16px] text-white">		Arrange Local Currency and Foreign Currency Debt. Co-ordinate Commercial, Environment and Social, Legal and Management Due Diligence processes.</li>
                          <li className="text-[16px] text-white">		Structure credit facilities. Take lead role in restructuring, reforming and renewing financial plans for cross sector organizations and institutions.</li>

                        </ul>
                        <p className=" text-[16px] font-bold my-3">Manufacturing Industry - following services can be accomplished within 6 to 12 months:</p>
                        <ul className="list-decimal px-6">
                          <li className="text-[16px] text-white">Establish efficient and dynamic Finance and Treasury Operations with skilled resources</li>
                          <li className="text-[16px] text-white">Negotiate and obtain Bank funding lines from local, foreign, public and private commercial banks</li>
                          <li className="text-[16px] text-white">Reduce costs efficiently managing Debt, LC, UPAS and other BDT/USD/EURO financial expenses</li>
                          <li className="text-[16px] text-white">Negotiate and manage all aspects of Foreign Currency related transactions</li>
                          <li className="text-[16px] text-white">Risk Management - Minimize FX Risk, Interest Rate Risk and Cash Flow mismatch risk</li>
                          <li className="text-[16px] text-white">Arrange local and foreign currency Long Term Funding for CAPEX related investments at competitive</li>
                          <li className="text-[16px] text-white">Support Business operations with adequate working capital financing at market competitive rates</li>
                          <li className="text-[16px] text-white">Build Treasury Dealing Room, Treasury Desk, Finance Desk and Debt Management functions for Corporate Financing</li>
                          <li className="text-[16px] text-white">Source Long Term Financing from multi-lateral agencies for Project Finance and BMRE CAPEX investments</li>
                          <li className="text-[16px] text-white">Make Project Profile, IM, Financial Model and 5-year Business Plan</li>
                        </ul>
                        <p className=" text-[16px] font-bold my-3">Financial Services - Following services can be accomplished within 6 to 12 months</p>
                        <ul className="list-decimal px-6">
                          <li className="text-[16px] text-white">Develop Credit Risk Management Guidelines for Corporate Customers</li>
                          <li className="text-[16px] text-white">Build Credit Memo addressing Critical Risks and Financial Analysis</li>
                          <li className="text-[16px] text-white">Develop Due Diligence processes and assessment criteria for Corporate Loans</li>
                          <li className="text-[16px] text-white">Train and Coach Junior staff to understand Credit Risks and Analysis</li>
                          <li className="text-[16px] text-white">Capacity development to understand Credit Risks, Structured Finance and Corporate Banking</li>
                          <li className="text-[16px] text-white">Improve presentation skills for preparing Credit Memos</li>
                          <li className="text-[16px] text-white">Create a culture for identifying Credit Risks and Mitigations</li>
                          <li className="text-[16px] text-white">Coach and train staff to understand Balance Sheet, P&L, Cash Flow</li>

                        </ul>
                      </div>
                      {/* Full content for consultant 2 */}

                    </div>
                  )}
                  <button onClick={toggleReadMoreLess5} className="flex text-fruit text-[16px] items-center justify-center mx-auto">
                    {isShowMore5 ? "Read Less" : "Read More"}
                  </button>
                </div>
              </div>
            </div>
          </div>


          
        </div>

      </div>



    </>
  )
}
