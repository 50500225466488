import React from 'react'
import { Link } from 'react-router-dom'
import ad1 from '../../../static/enteprice.webp'
import ad2 from '../../../static/ad2.avif'
import ad3 from '../../../static/ad3.avif'
import ad4 from '../../../static/rjsc.jpg'
import ad5 from '../../../static/ad5.avif'
import ad6 from '../../../static/ad6.avif'
import ad7 from '../../../static/as7.webp'
export default function AdvisoryService() {
  return (
    <div className=' bg-berry '>
    <div className='h-24 bg-berry overflow-x-hidden 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
              <h2 className='text-center my-4 overline text-xl lg:text-3xl
              text-white
              '>Advisory Service</h2>
          </div>
          <div class="p-1 flex flex-wrap items-center justify-center  ">
          <div class="flex justify-center items-center my-10 mx-10">
  
      <div class="grid grid-cols-1  sm:grid-cols-2 md:grid-col-3 lg:grid-cols-4 gap-6">
  
  <Link to='/enterprise'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
  
      <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
      <figure class="relative max-w-sm ">
    <a href="#">
      <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
      filter blur-0 hover:blur-sm"
       src={ad1}
       alt="image description"/>
    </a>
    <figcaption class="absolute px-4 text-xl  text-white bottom-6 text-reveal">
        <p className=''>Enterprise Risk Management Services</p>
    </figcaption>
  </figure>
  </div>
  </Link>
  <Link to='/buisnessprocess'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
  <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
      <figure class="relative max-w-sm ">
    <a href="#">
      <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
      filter blur-0 hover:blur-sm"
      src={ad2}
       alt="image description"/>
    </a>
    <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
        <p>Business Restructuring Services</p>
    </figcaption>
  </figure>
  </div>  
  </Link>
  <Link to='/buisness'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
    <div class="w-full max-w-sm bg-white rounded-lg shadow img-cards">
      <figure class="relative max-w-sm ">
    <a href="#">
      <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
      filter blur-0 hover:blur-sm"
      src={ad3}
       alt="image description"/>
    </a>
    <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
        <p>Business Process Integration</p>
    </figcaption>
  </figure>
  </div> 
  </Link>

  <Link to='/regulatory'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
   <div class="w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
      <figure class="relative max-w-sm ">
    <a href="#">
      <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
      filter blur-0 hover:blur-sm"
      src={ad4}
       alt="image description"/>
    </a>
    <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
        <p>RJSC, BIDA & Other Regulatory Services</p>
    </figcaption>
  </figure>
  </div> 
  </Link>
      
      </div>
  </div>
  
  <div class="flex justify-center items-center mt-6 my-10 ">
    
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-10 sm:mx-10 md:mx-10 lg:mx-44">
  
   
  
      <Link to='/staff'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
   <div class="w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
      <figure class="relative max-w-sm ">
    <a href="#">
      <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
      filter blur-0 hover:blur-sm"
      src={ad5}
       alt="image description"/>
    </a>
    <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
        <p>Staff Recruitment for Clients.</p>
    </figcaption>
  </figure>
  </div> 
  </Link> <Link to='/Finance'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
   <div class="w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
      <figure class="relative max-w-sm ">
    <a href="#">
      <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
      filter blur-0 hover:blur-sm"
      src={ad6}
       alt="image description"/>
    </a>
    <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
        <p>Corporate Finance </p>
    </figcaption>
  </figure>
  </div> 
  </Link> <Link to='/policy'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
   <div class="w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
      <figure class="relative max-w-sm ">
    <a href="#">
      <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
      filter blur-0 hover:blur-sm"
      src={ad7}
       alt="image description"/>
    </a>
    <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
        <p>Policy and Procedures development</p>
    </figcaption>
  </figure>
  </div> 
  </Link>
  
          
      </div>
  </div>
  
          </div>
          </div>
  )
}
