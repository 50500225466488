
import React, { useEffect, useState } from 'react';
import Counter from './Counter';

export default function CircularProgressBar() {

  return (
    <>

  <div className="flex  bg-fruit flex-col items-center justify-center border-2
   min-h-5/6 ">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 ">
 
          <div  className=" p-8  ">
            <h5 className="text-4xl font-bold mb-4"><Counter targetValue={31}/></h5>
            <p className='text-white text-center align-middle text-xl'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
         
         
              Our Team</p>
              <p className='text-white text-center align-middle text-lg'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
         
         
              We continue to grow nationally and internationally.</p>

          </div>
          <div  className=" p-8 ">
            <h5 className="text-4xl font-bold mb-4"><Counter targetValue={49}/></h5>
            <p className='text-white text-center align-middle text-lg'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
   
   
              Our Client</p>
              <p className='text-white text-center align-middle text-lg'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
         
         
              An experienced team and legacy of Success</p>
          </div>
          <div  className=" p-6 ">
            <h5 className="text-4xl text-white font-bold mb-4"><Counter targetValue={36}/></h5>
            <p className='text-white text-center align-middle text-xl'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
   
   
              Our Experience</p>
            <p className='text-white text-center align-middle text-xl'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">
         
         
            From different industries we cater to everyone</p>
          </div>
     
      </div>
    </div>

    </>
  )
}