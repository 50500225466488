import React from 'react'

export default function Disclaimer() {
  return (
    <div>
               <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl '>Disclaimer</h2>
        </div> 
        <div className='my-10 bg-berry mx-5 h-96 overflow-y-auto'>
        <p className='text-lg text-left text-white '>
        IMZI ,a Limited Liability Partnership firm,
         is dedicated to providing valuable insights and services to our clients.
         The contents on IMZI web pages is presented in general terms and should be
          considered as broad guidance. 
          It may not cover specific situations comprehensively.
          The information provided on our web pages is not a substitute 
          for specific professional advice. Individuals and businesses are 
          encouraged to seek personalized consultation from IMZI to address their
           unique circumstances.IMZI, its partners, employees, and agents do not accept or
            assume any liability or duty of care for any loss arising from actions taken or 
            not taken based on the information contained on our web pages. Decisions made in 
            reliance on this information are at the individual's or organization's own risk.
</p>
        </div>
    </div>
  )
}
