import React from 'react';
import ac1 from '../../static/a3.webp';

export default function CureentOpenings() {
  const email = "career@imzi-ca.com";
  return (
    <>
         <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Current openings</h2>
        </div> 
        <div className='flex items-center justify-center bg-berry drop-shadow-lg'>
  <div className='w-5/6 sm:w-3/4 md:w-2/3 lg:w-2/3 xl:w-2/3 bg-berry rounded-lg mt-4 mb-4'>
    <figure className='relative bg-berry'>
      <a href="#" className=''>
        <img
          className='rounded-lg w-full my-3 cursor-pointer filter blur-0 hover:blur-sm'
          src={ac1}
          alt="image description"
          data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000"
        />
      </a>
      <figcaption className='absolute px-4 text-lg text-white bottom-6 text-reveal'>
              <p className='text-2xl font-semibold'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Current Vacancies: Trainee Student</p>
              <p className='text-xl my-2'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Please Email Your CV at : 
              <a 
              // href={`mailto:${email}`}
               className='hover:underline hover:text-cyan'data-aos="zoom-in"data-aos-delay="50"
               data-aos-duration="1000"> career@imzi-ca.com</a>
</p>

            </figcaption>
    </figure>
  </div>
</div>
    </>
  );
}
