import React from 'react'
// import map from '../../static/map.jpg'
import Map from './Map'

export default function GoogleMap() {
  return (
    <>
      <div className='flex flex-col lg:flex-row items-center justify-center bg-berry'>
  <div className='w-full lg:w-1/2 my-10 mx-10'>
    <div class="grid grid-col-3 ">
    <div className='mx-2 thumb'>
  {/* <img className="w-full h-auto max-h-96 rounded-lg" 
    src={map} alt=""/> */}
    <div className="w-full h-auto max-h-96 rounded-lg" data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
   <Map/>
    </div>
</div>

  
    </div>
  </div>
  <div className='w-4.6 lg:w-1/2 my-10 mx-12 lg:mx-10 justify-end align-middle items-center'>
  <div className='mx-10'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

  <p className='text-white text-md my-3'>Contact us: info@imzi-ca.com</p>
  <p className='text-white text-md my-3'>Careers: career@imzi-ca.com</p>
  <p className='text-white text-md my-3'>Address : Suite- 1103, Level- 11, 28 Dilkusha C/A, Dhaka – 1000</p>
  <p className='text-white text-md my-3'>Contact No : +880 1886-692428 </p>
  <div class="flex  space-x-5  md:mt-0 mx-3 ">
            <a href="https://www.facebook.com/IMZI.ADVISORY/"  target='_blank' class="text-white hover:text-gray-500 mt-3 
             
             ">
                  <svg class="w-7 h-6  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
        
            
              <a href=" http://www.youtube.com/@IMZIQuest
" target='_blank' class="text-white mt-3 
               hover:text-gray-500
               ">
                  <svg class="w-7 h-6 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z"/>
                </svg>
                  <span class="sr-only">Twitter page</span>
              </a>
              <a href=" 	https://www.linkedin.com/company/imzi-ca-advisory/


" target='_blank' class="text-white mt-3 
               hover:text-gray-500
               ">
                <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401m-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4"/>
                  </svg>
                  <span class="sr-only">Twitter page</span>
              </a>
        </div>  
</div>
  </div>
</div>
    </>
  )
}
