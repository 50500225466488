import React from 'react'
import mearges from '../../../static/Mergers & Acquisition.jpg'
export default function Mearges() {
  return (
    <div>
    <div className='bg-orange-50'>

    <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Mergers & Acquisitions Advisory</h2>
        </div> 
  </div>
  <div className='bg-berry'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4 my-20 justify-center items-center appear '>

<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Mergers & Acquisitions Advisory Services</p>
<div class="thumbanail right h-72 "data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={mearges} alt="" />
    </div>
<p class="text-white  text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
IMZI is your trusted partner in navigating the complexities of Mergers & Acquisitions (M&A), offering specialized advisory services that guide businesses through every stage of the deal-making process. Our seasoned team of M&A experts combines industry insight with strategic acumen to deliver comprehensive solutions tailored to your unique business objectives.

</p>



<p class="text-white text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Begin your M&A journey with strategic planning tailored to your business objectives. Our experts work closely with clients to develop M&A strategies that align with growth goals, market expansion, and overall corporate vision.   

</p>


<p class="text-white text-[16px]"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
Our team conducts comprehensive assessments to evaluate the financial health, operational efficiency, and strategic fit of potential targets, ensuring informed decision-making. Optimize deal outcomes with accurate valuation and pricing strategies. We employ rigorous methodologies to determine fair market value, enabling clients to negotiate favorable terms and maximize returns on their M&A transactions. 

Streamline the regulatory process with our expertise in compliance and approvals. We guide clients through the regulatory landscape, ensuring that M&A transactions adhere to legal requirements and industry standards. However, Ensure a smooth transition and optimize synergies with our post-merger integration services. Our team assists in aligning operations, cultures, and systems to realize the full potential of the merged entities.

</p>

</div>
</div>

  </div>

  </div>
  )
}
