import React, { useState, useEffect } from 'react';
import './css/Home.css';
import OurTeam from './OurTeam';
import OurService from './OurService';
import GetInTouch from './GetInTouch';
import Resouces from './Resouces';
import CircularProgressBar from './CircularProgressBar';

export default function Home() {
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    // Set an interval to trigger the reset every 8.5 seconds
    const interval = setInterval(() => {
      setResetKey((prev) => prev + 1); // Reset the key to trigger re-render
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  // Split the text into parts for each word
  const imziText = "IMZI".split("");
  const advisoryText = "Advisory".split("");

  return (
    <>
      <div className="box h-80 flex items-center overflow-y-auto">
        <div className="flex items-end w-full text-reveal">
          <div
            className="text-center mx-20 md:mx-20"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          >
            <div className="text-cyan h-auto">
              <h2 className="text-white text-lg text-left w-auto sm:text-4xl md:max-w-md lg:text-2xl" key={resetKey}>
                {/* IMZI Advisory with animation */}
                <span>
                  {imziText.map((char, index) => (
                    <span key={index} style={{ animationDelay: `${index * 0.2}s`, display: "inline-block" }}>
                      {char}
                    </span>
                  ))}
                </span>
                <span>&nbsp;</span> {/* Add space between words */}
                <span>
                  {advisoryText.map((char, index) => (
                    <span key={index} style={{ animationDelay: `${(imziText.length + index) * 0.2}s`, display: "inline-block" }}>
                      {char}
                    </span>
                  ))}
                </span>
              </h2>
            </div>
            <p className="text-[16px] mt-3 text-white max-w-md text-left">
              Distinguished Premier Chartered Accountants and Advisors with Globally Renowned Expertise and Unrivaled Professional Insight.
            </p>
          </div>
        </div>
      </div>

      <OurTeam />
      <CircularProgressBar />
      <OurService />
      <Resouces />
      <GetInTouch />
    </>
  );
}
