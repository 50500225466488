import React from 'react'
import tax from '../../../static/tax.webp'
export default function TransferPricing() {
  return (
    <div className=''>
              <div className='h-24  overflow-x-hidden bg-cyan'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Transfer Pricing Services</h2>
        </div> 
      <div className='flex flex-col lg:flex-row items-center justify-center bg-cyan '>
  <div className='w-full lg:w-1/2 my-10 mx-10'>
    <div class="grid grid-col-2 ">
    <div className='mx-10 thumb'data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
  <img className="w-full h-auto max-h-96 rounded-lg" 
    src={tax} alt=""/>
</div>

  
    </div>
  </div>
  <div className='w-4.6 lg:w-1/2 my-10 mx-4  justify-end align-middle items-center appear'>
  
        <p className='mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
   Transfer Pricing Services 
    </p>
    <p className=' text-[16px] text-white'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="2000">IMZI offers specialized Transfer Pricing Services
     designed to help businesses navigate the complexities
      of cross-border transactions and ensure 
      compliance with transfer pricing regulations. Our services begin with strategic transfer pricing planning, tailored to optimize your global tax position and align with your overall business strategy. We analyze intercompany transactions to ensure they reflect fair market value, minimize tax risks, and support your organization's financial objectives.
    </p>
  </div>
</div>
    </div>
  )
}
