import React from 'react'
import buisness from '../../../static/Recruitment.jpg'
export default function Buisnessrocess() {
  return (
    <div className=''>
    <div className='bg-cyan'>
    <div className='h-24  overflow-x-hidden bg-cyan'>
            <h2 className='text-center text-white my-4 overline text-[16px] lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Business Restructuring Services</h2>
        </div> 
<div class="p-1 items-center justify-center   ">

<div className='mx-4 my-2  justify-center items-center appear  '>

<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Business Restructuring Services</p>

<p class="text-white  text-[16px]"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
IMZI is your strategic partner in Business Restructuring, offering specialized services to help organizations navigate change, enhance efficiency, and position themselves for sustainable success. Our dedicated team of experts collaborates closely with clients to develop tailored restructuring solutions 
that align with their unique business goals and challenges.

</p>

<div class="thumbanail right h-72 "data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={buisness} alt="" />
    </div>

</div>
</div>

</div>
<div className='bg-cyan'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4 my-2  justify-center items-center appear '>


<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Financial Restructuring:</p>
<p class="text-white  text-[16px]"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
Optimize your financial structure with our Financial Restructuring Services. We analyze existing financial frameworks, assess debt obligations, and develop strategic plans to improve liquidity, 
reduce financial risks, and enhance overall financial stability.

</p>



</div>
</div>

</div>

<div className='bg-cyan '>
<div class="p-1 items-center justify-center  ">

<div className='mx-4 my-2  justify-center items-center appear '>


<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Operational Restructuring:</p>

<p class="text-white  text-[16px]"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
Enhance operational efficiency through our Operational Restructuring Services. We evaluate business processes, identify inefficiencies, and implement solutions to streamline operations, improve productivity, and reduce costs.


</p>



</div>
</div>

</div>
<div className='bg-cyan'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4 my-2 justify-center items-center appear '>


<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Organizational Restructuring:</p>

<p class="text-white text-[16px]"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
Align your organizational structure with strategic objectives. Our services include organizational reviews, role restructuring, and talent optimization strategies to ensure that your workforce is aligned with your business goals.

</p>


</div>
</div>

</div>
</div>
  )
}
