import React from 'react'
import './css/GetInTouch.css'
import getintouch from '../../static/getintouch.webp'
import { Link } from 'react-router-dom'
export default function GetInTouch() {
  return (
    <>
   <div className='bg-fruit'>
   <div className='h-24 overflow-x-hidden'>

  

<h2 className='text-center my-4 lg:text-3xl text-xl overline text-white'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">Get In Touch</h2>
</div>
  
      <div  class="flex flex-col 
      items-center 
        
         md:flex-row w-full h-auto
         ">
     
    <img class="h-96  w-96 lg:w-1/2 mx-10 shadows appear transition ease-in-out delay-105 hover:-translate-y-1 hover:scale-110 duration-300" 
    src={getintouch}
     alt=""
     data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000"
     />
    <div class="flex flex-col justify-between p-4 leading-normal"data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-white
         ">IMZI  </h5>
         <p className='text-white text-sm'>Chartered Accountants & Advisory Firm</p>
        <p class="mb-3 font-normal  text-[16px] text-white my-2">Cultivating Sustainable Success via Innovative Solutions, Trust-Forged Relationships, and a Unified Team of Expert Leaders in Audit and Accounting.</p>
<div className='h-full '>

<Link to='lifeImzi' class="w-auto text-gray-900 bg-tranparent rounded-lg ">
    <button type="button" class="relative inline-flex items-center w-4/5
 py-2 text-2xl font-medium border-b
      border-cyan-200 rounded-t-lg text-white my-3
      transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300
     ">
        <svg class="w-5 h-19 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
        </svg>
        Life at IMZI
    </button>
    <Link to='/currentopenings' type="button" class="relative inline-flex items-center w-4/5
 py-2 text-2xl font-medium border-b my-3
      border-cyan-200 rounded-t-lg text-white 
      transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300
     ">
        <svg class="w-5 h-19 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
        </svg>
        Current Openings
    </Link>

    <Link to='/getintouch' type="button" class="relative inline-flex items-center w-4/5
 py-2 text-2xl font-medium border-b my-3
      border-cyan-200 rounded-t-lg text-white 
      transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300
     ">
        <svg class="w-5 h-19 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
        </svg>
        Contact Info
    </Link>
    <Link to ='map' type="button" class="relative inline-flex items-center w-4/5
 py-2 text-2xl font-medium border-b my-3
      border-cyan-200 rounded-t-lg text-white 
      transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300
     ">
        <svg class="w-5 h-19 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
        </svg>
        Google Map
    </Link>
</Link>

</div>
    </div>
</div>
   </div>


    </>
  )
}
