import React from 'react'
import custom from '../../../static/Custom.jpg'
export default function CustomConsulting() {
  return (
    <div>
       <div>
       <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Customs Consulting</h2>
        </div> 
             <div className='bg-berry'>
 <div class="p-1 items-center justify-center  ">

<div className='mx-4 my-10 justify-center items-center appear '>

 
<p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> Customs Consulting Services</p>

<p class="mb-3 text-[16px] text-white "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">IMZI offers specialized Customs Consulting Services designed to facilitate seamless cross-border trade and ensure compliance with intricate customs regulations. Our team of experienced customs consultants provides strategic guidance, operational support, and compliance solutions to optimize your international trade processes and mitigate risks.

.</p>
<p class="mb-3 text-[16px] text-white ">Navigate the complexities of customs compliance with confidence. Our services encompass comprehensive customs documentation and regulatory compliance, ensuring that your international trade transactions meet all legal requirements. We also provide services for</p>

<div class="thumbanail right h-52 "data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={custom} alt="" />
    </div>

<dl class="max-w-md text-white divide-y divide-gray-500  my-5 text-reveal">
    <div class="flex flex-col pb-3">
       
        <dd class="text-[16px] font-semibold "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Tariff Classification and Valuation:</dd>
    </div>
    <div class="flex flex-col py-3">
        
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Duty Drawback and Refund Programs: </dd>
    </div>
    <div class="flex flex-col pt-3">
       
        <dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Trade Compliance Audits:</dd>
    </div>
</dl>



 </div>
 </div>

    </div>
    </div>
    </div>
  )
}
