import React from 'react'
import policy from '../../../static/policy.avif'
export default function Policy() {
  return (
    <div>
    <div className='bg-berry'>
    <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Policy And Procedures Development</h2>
        </div> 
<div class="p-1 items-center justify-center  ">

<div className='mx-4 my-5 justify-center items-center appear '>




<p class="mb-3 text-[16px] text-white"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000"> IMZI has a team of experienced professionals who can help you to develop and implement policies and procedures that are tailored to your specific needs.

.</p>

<p class="mb-3 text-[16px] text-white my-5"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
We understand that every organization is different, and that there is no one-size-fits-all solution to policy and procedural development. That is why we take the time to understand your business and your goals before we begin any work. We also involve you throughout the development process to ensure that you are satisfied with the results. 
.</p>






</div>
</div>

</div>
<div className='bg-berry'>
<div class="p-1 items-center justify-center  ">

<div className='mx-4  justify-center items-center appear '>


<p class="mb-3 text-[16px] text-white   "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
We believe that a well-developed set of policies and procedures is essential for any organization. Policies and procedures can help to: 
.</p>



<div class="thumbanail right h-auto "data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={policy} alt="" />
    </div>

<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
<div class="flex flex-col pb-3">

<dd class="text-[16px] font-semibold "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Improve communication and coordination within the organization.</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">	Reduce the risk of errors and omissions. </dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
data-aos-duration="1000">Ensure compliance with laws and regulations.</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Protect the organization from liability</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Improve efficiency and productivity</dd>
</div>
<div class="flex flex-col py-3"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">

<dd class="text-[16px] font-semibold"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Promote a positive and productive work environment.</dd>
</div>
</dl>

</div>
</div>

</div>
</div>
  )
}
