import React from 'react'
import './css/controlService.css'
import cor from '../../../static/coe.webp'
export default function CorporateGovernance() {
  return (
    <>
    <div>
    <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Corporate Governance & Compliance Audit

</h2>
        </div> 
      <div className='flex flex-col lg:flex-row items-center justify-center bg-berry'>
<div className='w-full   '>
<div class='bg-berry mx-2   appear'>
    <div class="  mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20 ">
        <div class="thumbanail right my-8 shadow-2xl rounded-full h-auto w-auto"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">
            <img  src={cor} alt="" />
        </div>
        
        <p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in-down"data-aos-delay="50"
 data-aos-duration="1000"> Corporate Governance & Compliance Audit</p>
    
        <p class=' text-[16px] text-left my-4 text-white 'data-aos="zoom-in-down"data-aos-delay="50"
 data-aos-duration="1000">
        We understand the pivotal role that robust corporate governance and compliance play in sustaining business integrity and fostering stakeholder trust. Our Corporate Governance & Compliance Audit services are meticulously designed to assist organizations in navigating the intricate landscape of regulatory requirements, ethical standards, and best practices.


        </p>
        <p class=' text-[16px]  text-left my-5 text-white 'data-aos="zoom-in-down"data-aos-delay="50"
 data-aos-duration="1000">
        Our expert auditors conduct thorough assessments of your organization's governance structure, policies, and practices. We identify areas for improvement, ensuring that your governance framework aligns with industry standards and promotes transparency, accountability, and ethical conduct.


        </p>
        <p class=' text-[16px] text-left my-5 text-white'data-aos="zoom-in-down"data-aos-delay="50"
 data-aos-duration="1000">
        Recognizing the uniqueness of each business, our Corporate Governance & Compliance Audit services are tailored to address your specific needs. Whether you are a multinational corporation or a growing enterprise, our solutions are crafted to add value and drive sustainable growth.
.



        </p>
    </div>
</div>

  </div>
  
 
</div>
    </div>

    </>
  )
}
