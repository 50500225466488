import React from 'react'
import './css/audit.css'
import { Link } from 'react-router-dom'
import a1 from '../../../static/auditservice.webp'
import a2 from '../../../static/intcontrol.webp'
import a3 from '../../../static/a3.webp'
import a4 from '../../../static/forendicaudit.avif'
import a5 from '../../../static/informationaudit.jpg'
import a6 from '../../../static/buisnessintregation.avif'
export default function AuditandAssurance() {
  return (
    <>
  <div className=' bg-berry '>
  <div className='h-24 bg-berry overflow-x-hidden 'data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">
            <h2 className='text-center my-4 overline text-xl lg:text-3xl
            text-white
            '>Audit & Assurance</h2>
        </div>
        <div class="p-1 flex flex-wrap items-center justify-center  ">
        <div class="flex justify-center items-center my-10 mx-10">

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">

<Link to='/auditservice'data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">

    <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#">
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
     src={a1} 
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-center text-white bottom-6 text-reveal">
      <p className='text-center'>Audit Services</p>
  </figcaption>
</figure>
</div>
</Link>
<Link to='/controlservice'data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">
<div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#">
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
     src={a2}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>Internal Control System Design & Development</p>
  </figcaption>
</figure>
</div>  
</Link>
<Link to='/corporategovernce'data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">
  <div class="w-full max-w-sm bg-white rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#">
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
     src={a3}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>Corporate Governance & Compliance Audit</p>
  </figcaption>
</figure>
</div> 
</Link>
<Link to='/inventional'data-aos="zoom-in-down"data-aos-delay="50"
     data-aos-duration="1000">

<div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
 <figure class="relative max-w-sm ">
<a href="#">
 <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
 filter blur-0 hover:blur-sm"
 src={a4}
 alt="image description"/>
</a>
<figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
   <p>Investigation and Forensic Audit</p>
</figcaption>
</figure>
</div>
</Link>     
<Link to='/informationaudit'data-aos="zoom-in-down"data-aos-delay="50"
     data-aos-duration="1000">

    <div class="w-full max-w-sm bg-white  rounded-lg shadow img-cards">
    <figure class="relative max-w-sm ">
  <a href="#">
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
     src={a5}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>Information System Audit </p>
  </figcaption>
</figure>
</div>   
</Link>
<Link to='/auditbuisnessprocess'data-aos="zoom-in-down"data-aos-delay="50"
     data-aos-duration="1000">
 <div class="w-full max-w-sm bg-white  rounded-lg shadow .img-cards">
    <figure class="relative max-w-sm ">
  <a href="#">
    <img class="rounded-lg transition-all duration-300 cursor-pointer h-96 w-96
    filter blur-0 hover:blur-sm"
     src={a6}
     alt="image description"/>
  </a>
  <figcaption class="absolute px-4 text-xl text-white bottom-6 text-reveal">
      <p>Business Process Integration/Process Re-engineering</p>
  </figcaption>
</figure>
</div> 
</Link>
    
    </div>
</div>

{/* <div class="flex justify-center items-center mt-6 my-10 ">
  
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-10 sm:mx-10 md:mx-10 lg:mx-44">

 



        
    </div>
</div> */}

        </div>
        </div>
    </>
  )
}
