import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IHCA from '../../static/IHCA.png';
import bglogo from '../../static/logoblue.png';
import { Navbar } from 'flowbite-react';
import './css/Header.css'
export default function Header() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isAbout, setAbout] = useState(false);
    const [isService, setService] = useState(false);
    const [isResource, setResource] = useState(false);
    const [isPeople, setPeople] = useState(false);
    const [isContact, setContact] = useState(false);
    const [isAboutOpen, setAboutOpen] = useState(false);
  const [isServicesOpen, setServicesOpen] = useState(false);
  const [isResourcesOpen, setResourcesOpen] = useState(false);

    const [visible, setVisible] = useState(false) 
    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };
    const handleAboutClick = () => {
        setAbout(!isAbout);
   
      };
      
      const handleServiceClick = () => {
        setService(!isService);
    
      };
      const handleResourceClick = () => {
        setResource(!isResource);
    
      };
      const handlePeopleClick = () => {
        setPeople(!isPeople);
    
      };
      const handleContactClick = () => {
        setContact(!isContact);
    
      };
    const toggleVisible = () => setVisible(!visible) 
  
    const handleMouseEnter = (menuType) => {
        switch (menuType) {
          case 'about':
            setAbout(!isAbout);
                setService(false)
                setResource(false)
                setPeople(false)
                setContact(false)
            break;
          case 'services':
            setService(!isService);
                setAbout(false)
                setResource(false)
                setPeople(false)
                setContact(false)
            break;
          case 'resources':
            setResource(!isResource);
                setService(false)
                setAbout(false)
                setPeople(false)
                setContact(false)
                break;
                case 'people':
                    setPeople(!isPeople);
                        setService(false)
                        setAbout(false)
                        setResource(false)
                        setContact(false)
            break;
            case 'contact':
                setContact(!isContact);
                    setService(false)
                    setAbout(false)
                    setPeople(false)
                    setResource(false)
        break;
          default:
            break;
        }
      };
    
      const handleMouseLeave = () => {
        setContact(false);
        setService(false)
        setAbout(false)
        setPeople(false)
        setResource(false)

      };
    


      
   
  return (
    <>
      



         <nav class="bg-orange-50 border-gray-200
      "  >
    <div class="flex flex-wrap 
  justify-between max-w-screen-xl p-3">
<a href="/" class="flex items-center mb-4 sm:mb-0 rtl:space-x-reverse my-0 lg:my-2">
  <img src={bglogo} class="h-8 lg:h-12 xl:h-16 mb-15  lg:mb-0 " alt="Flowbite Logo" />
  
</a>


        <div class="flex  items-center md:order-3">
      

            <button    onClick={toggleMenu}
      data-collapse-toggle="mega-menu-icons"
      type="button"
      className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-amber-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
      aria-controls="mega-menu-icons"
      aria-expanded={isMenuOpen}>
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                </svg>
            </button>
        </div>
        
        <div id="mega-menu-icons"    aria-expanded={isMenuOpen} onMouseLeave={handleMouseLeave}
      className={`items-center justify-between ml-auto w-full md:flex md:w-auto md:order-1 ${
        isMenuOpen ? 'block' : 'hidden'
      }`}>

            <ul class="flex  mt-4 font-medium flex-col sm:flex-col  md:flex-row 
             md:space-x-6 md:mt-0">

                <li>
                <button 
    aria-expanded={isAbout}   onClick={handleAboutClick}
    onMouseEnter={() => handleMouseEnter('about')}
    data-dropdown-toggle="mega-menu-icons-dropdown"
    class="flex items-center justify-between w-full  py-2 pl-3 pr-4 text-xl md:w-auto
     text-cyan  md:p-0 hover:scale-110 context"
>
    About Us
</button>
{isAbout && (
                      <div    
                    class='absolute z-10 grid w-auto grid-cols-2
                     text-sm bg-orange-50 shadow-inner
                      rounded-lg   my-2
                      md:grid-cols-3 
                      '
                    >
                   
                        <div class="p-4 pb-0 text-gray-900 md:pb-4 
                         ">
                            <ul class="space-y-4"  aria-labelledby="mega-menu-icons-dropdown-button">
                                <Link to='/whoweare' className='my-5'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg
                                      hover:text-orange-500
                                      hover:scale-110
                                        group">
                                        <span class="sr-only">About us</span>
                                        <svg  className='w-3 h-3 mr-2 
                                         text-cyan text-lg
                                         hover:scale-110
                                           group-hover:text-orange-500
                                           " aria-hidden="true'
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
</svg>
                                        Who We Are
                                    </a>
                                </Link>
                                <Link to='/ourteam' className='my-5'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg
                                      hover:text-orange-500
                                      hover:scale-110
                                        group my-2">
                                        <span class="sr-only">Our Team</span>
                                        <svg class="w-4 h-4 mr-2
                                         text-cyan text-lg
                                          group-hover:text-orange-500
                                          hover:scale-110
                                           " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                                        </svg>
                                       Our Team
                                    </a>
                                </Link>
                              
                             
                            </ul>
                        </div>
                        <div class="flex p-4 pb-0 text-gray-900 md:pb-4 dark:text-white">
                            <ul class="space-y-4">
                                <Link to='/ourpurpose'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg
                                      hover:text-orange-500
                                      hover:scale-110
                                        group">
                                        <span class="sr-only">Blog</span>
                                        <svg class="w-3 h-3 mr-2
                                         text-cyan text-lg
                                          group-hover:text-orange-500
                                          hover:scale-110
                                           " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M19 4h-1a1 1 0 1 0 0 2v11a1 1 0 0 1-2 0V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a1 1 0 0 0-1-1ZM3 4a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Zm9 13H4a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-3H4a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-3H4a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-3h-2a1 1 0 0 1 0-2h2a1 1 0 1 1 0 2Zm0-3h-2a1 1 0 0 1 0-2h2a1 1 0 1 1 0 2Z"/>
                                            <path d="M6 5H5v1h1V5Z"/>
                                        </svg>
                                        Our Purpose
                                    </a>
                                </Link>
                             
                                <Link to='/ourvision'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg
                                      hover:text-orange-500
                                      hover:scale-110 my-2
                                        group">
                                        <span class="sr-only">Playground</span>
                                        <svg class="w-3 h-3 mr-2 hover:scale-110
                                         text-cyan text-lg
                                          group-hover:text-orange-500
                                           " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                            <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10ZM17 13h-2v-2a1 1 0 0 0-2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2Z"/>
                                        </svg>
                                     Our Vision
                                    </a>
                                </Link>
                            
                            </ul>
                        </div>
                        <div class="flex p-4 text-gray-900">
                            <ul class="space-y-2">
                                <Link to='/ourflagship'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg
                                     hover:scale-110 my-0
                                       hover:text-orange-500 
                                        group">
                                        <span class="sr-only">Contact Us</span>
                                        <svg class="w-3 h-4 mr-2
                                         text-cyan text-lg hover:scale-110
                                          group-hover:text-orange-500
                                           " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 20">
                                        <path d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a.998.998 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207Z"/>
  <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"/>
                                        </svg>
                                       Our FlagShip Behavior
                                    </a>
                                </Link>
                           
                            </ul>
                        </div>
                        </div>
                            )}
                </li>
                <li>
                    <button       onMouseEnter={() => handleMouseEnter('services')}
               onClick={handleServiceClick}
                    data-dropdown-toggle="mega-menu-icons-dropdown" class="flex items-center
                     justify-between w-full py-2 pl-3 pr-4 text-xl md:w-auto
                    text-cyan  md:p-0 hover:scale-110 context ">
                        Service

                    </button>
                    {isService && (              
                    <div id="mega-menu-icons-dropdown"   
                      class={`absolute z-10 grid w-auto grid-cols-2 mx-6
                      text-sm bg-orange-50 shadow-inner my-2
                       rounded-lg  
                       md:grid-cols-2
                      ${isService ? 'block' : 'hidden'} `}
                     
                    >
                        <div class="p-4 b-0 text-gray-900 md:pb-4
                         ">
                            <ul class="space-y-4" aria-labelledby="mega-menu-icons-dropdown-button">
                                <Link to='auditassurance'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg my-2
                                     hover:scale-110
                                       hover:text-orange-500 
                                        group
">
                                        <span class="sr-only">About us</span>
                                        <svg class="hover:scale-110
w-3 h-4 mr-2
text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                                        </svg>
                                        Audit & Assurance
                                    </a>
                                </Link>
                                <Link to='accountingbuisness'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg my-2
                                     hover:scale-110
                                       hover:text-orange-500 
                                        group">
                                        <span class="sr-only">Our Team</span>
                                        <svg class="w-3 h-4 mr-2 hover:scale-110
                                         text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="m1.56 6.245 8 3.924a1 1 0 0 0 .88 0l8-3.924a1 1 0 0 0 0-1.8l-8-3.925a1 1 0 0 0-.88 0l-8 3.925a1 1 0 0 0 0 1.8Z"/>
                                            <path d="M18 8.376a1 1 0 0 0-1 1v.163l-7 3.434-7-3.434v-.163a1 1 0 0 0-2 0v.786a1 1 0 0 0 .56.9l8 3.925a1 1 0 0 0 .88 0l8-3.925a1 1 0 0 0 .56-.9v-.786a1 1 0 0 0-1-1Z"/>
                                            <path d="M17.993 13.191a1 1 0 0 0-1 1v.163l-7 3.435-7-3.435v-.163a1 1 0 1 0-2 0v.787a1 1 0 0 0 .56.9l8 3.925a1 1 0 0 0 .88 0l8-3.925a1 1 0 0 0 .56-.9v-.787a1 1 0 0 0-1-1Z"/>
                                        </svg>
                                        Accounting & Business Process Outsourcing
                                    </a>
                                </Link>
                              
                                
                            </ul>
                        </div>
                        <div class="p-4 pb-0 text-gray-900 md:pb-4
                         ">
                            <ul class="space-y-4">
                                <Link to='/taxationService'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg my-2
                                     hover:scale-110
                                       hover:text-orange-500 
                                        group
">
                                        <span class="sr-only">Blog</span>
                                        <svg class="hover:scale-110


w-3 h-4 mr-2
text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M19 4h-1a1 1 0 1 0 0 2v11a1 1 0 0 1-2 0V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a1 1 0 0 0-1-1ZM3 4a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Zm9 13H4a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-3H4a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-3H4a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-3h-2a1 1 0 0 1 0-2h2a1 1 0 1 1 0 2Zm0-3h-2a1 1 0 0 1 0-2h2a1 1 0 1 1 0 2Z"/>
                                            <path d="M6 5H5v1h1V5Z"/>
                                        </svg>
                                        Taxation Service
                                    </a>
                                </Link>
                             
                                <Link to='/corporatefinance' >
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg my-2
                                     hover:scale-110
                                       hover:text-orange-500 
                                        group">
                                        <span class="sr-only">Playground</span>
                                        <svg class="w-3 h-4 mr-2 hover:scale-110
                                         text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                        <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z"/>
                                        </svg>
                                        Corporate Finance 
                                    </a>
                                </Link>
                                <Link to='/advisoryService'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg hover:scale-110
                                      my-2
                                       hover:text-orange-500 
                                        group
">
                                        <span class="sr-only">Playground</span>
                                        <svg class="hover:scale-110
w-3 h-4 mr-2
text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                                        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
  <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
  <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                                        </svg>
                                        Advisory Services 
                                    </a>
                                </Link>
                            </ul>
                        </div>
                     
                        </div>
                    )}
                </li>
                <li>
                    <button   onMouseEnter={() => handleMouseEnter('resources')} onClick={handleResourceClick}
                    id="mega-menu-icons-dropdown-button" data-dropdown-toggle="mega-menu-icons-dropdown" 
                    class="flex items-center justify-between w-full py-2 pl-3 pr-4 text-xl md:w-auto
                    text-cyan  md:p-0 hover:scale-110 context">
                        Resources
   
                    </button>
                    {isResource && (
                    <div id="mega-menu-icons-dropdown" 
                   class={`absolute z-10 grid w-auto grid-cols-2
                    text-sm bg-orange-50 my-2
                     rounded-lg shadow-inner 
                     md:grid-cols-1 
                    ${isResource ? 'block' : 'hidden'} `}
                    >
                        <div class="p-4 pb-0 text-gray-900 md:pb-4 ">
                            <ul class="space-y-4" aria-labelledby="mega-menu-icons-dropdown-button">
                                <Link to='/insights'>
                                    <a  class="flex items-center
                                     text-cyan my-2 text-lg
                                     hover:scale-110
                                       hover:text-orange-500 
                                        group
">
                                        <span class="sr-only">About us</span>
                                        <svg class="hover:scale-110

w-3 h-4 mr-2
                                         text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
                                        </svg>
                                        Insights
                                    </a>
                                </Link>
                                <Link to='/newsroom'>
                                    <a href="#" class="flex items-center
                                     text-cyan my-2 text-lg
                                     hover:scale-110
                                       hover:text-orange-500 
                                        group



">
                                        <span class="sr-only">Our Team</span>
                                        <svg class="hover:scale-110


w-3 h-4 mr-2
                                         text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"/>
  <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
                                        </svg>
                                        Newsroom
                                    </a>
                                </Link>
                                <Link to='/library'>
                                    <a  class="flex items-center
                                     text-cyan my-2 text-lg
                                     hover:scale-110
                                       hover:text-orange-500 
                                        group

">
                                        <span class="sr-only">Our Team</span>
                                        <svg class="hover:scale-110

w-3 h-4 mr-2
                                         text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z"/>
                                        </svg>
                                        Library
                                    </a>
                                </Link>
                              
                                
                            </ul>
                        </div>
                      
                    </div>        )}
                </li>
                <li>
                    <button    onMouseEnter={() => handleMouseEnter('people')} onClick={handlePeopleClick}
                    id="mega-menu-icons-dropdown-button" 
                    data-dropdown-toggle="mega-menu-icons-dropdown" 
                    class="flex items-center justify-between w-full py-2 pl-3 pr-4 text-xl md:w-auto
                    text-cyan  md:p-0 hover:scale-110 context">
                        People
  
                    </button>
                    {isPeople && (
                    <div id="mega-menu-icons-dropdown"   
                   class={`absolute z-10 grid w-auto grid-cols-2
                   text-sm bg-orange-50 my-2
                    rounded-lg shadow-inner 
                    md:grid-cols-1  
                    ${isPeople ? 'block' : 'hidden'} `}
                    >
                        <div class="p-4 pb-0 text-gray-900 md:pb-4 ">
                            <ul class="space-y-4" aria-labelledby="mega-menu-icons-dropdown-button">
                                <Link to='/lifeImzi'>
                                    <a href="#" class="flex items-center
                                     text-cyan my-2 text-lg 
                                     hover:scale-110
                                       hover:text-orange-500 
                                        group

">
                                        <span class="sr-only">About us</span>
                                        <svg class="hover:scale-110
w-3 h-4 mr-2
text-cyan  text-lg 
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                                        </svg>
                                        Life at IMZI
                                    </a>
                                </Link>
                                <Link to='/currentopenings'>
                                    <a href="#" class="flex items-center
                                     text-cyan my-2 text-lg 
                                     hover:scale-110
                                       hover:text-orange-500 
                                        group">
                                        <span class="sr-only">Our Team</span>
                                        <svg class="w-3 h-4 mr-2 hover:scale-110
                                         text-cyan text-lg 
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                                        </svg>
                                        Current Openings
                                    </a>
                                </Link>
                            
                              
                                
                            </ul>
                        </div>
                      
                    </div>)}
                </li>
                <li>
                    <button    onMouseEnter={() => handleMouseEnter('contact')} onClick={handleContactClick}
                    id="mega-menu-icons-dropdown-button" 
                    data-dropdown-toggle="mega-menu-icons-dropdown" 
                    class="flex items-center justify-between w-full py-2 pl-3 pr-4 text-xl md:w-auto
     text-cyan  md:p-0 hover:scale-110 context">
                        Contact
   
                    </button>
                    {isContact && (
                    <div id="mega-menu-icons-dropdown"   
                   class={`absolute z-10 grid md;w-72 lg:w-80 grid-cols-2
                    text-sm bg-orange-50 my-2
                     rounded-lg shadow-inner 
                     md:grid-cols-1 
                    ${isContact ? 'block' : 'hidden'} `}
                    >
                        <div class="p-4 pb-0  text-gray-900 md:pb-4 
                         ">
                            <ul class="space-y-4  " aria-labelledby="mega-menu-icons-dropdown-button">
                                <Link to='/getintouch'>
                                    <a href="#" class="flex items-center
                                     text-cyan text-lg
                                     hover:scale-110 my-2
                                       hover:text-orange-500 
                                        group


 ">
                                        <span class="sr-only">About us</span>
                                       
                                        <svg class="w-3 h-4 mr-2 hover:scale-110
                                         text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
                                        </svg>
                                        Office Address & Contact Info
                                    </a>
                                </Link>
                                <li>
                                    <Link to="/map" class="flex items-center
                                     text-cyan text-lg
                                     hover:scale-110 my-2
                                       hover:text-orange-500 
                                        group
">
                                        <span class="sr-only">Our Team</span>
                                        <svg class="w-3 h-4 mr-2 hover:scale-110
                                         text-cyan text-lg
                                          group-hover:text-orange-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
                                        </svg>
                                        Google Map
                                    </Link>
                                </li>
                                
                              
                                
                            </ul>
                        </div>
                      
                    </div>)}
               
                </li>
                <li className=''>
                <div className=''>
                {/* <Link to='/address&contact' class="   relative inline-flex items-center top-0 px-2 lg:py-1 
                overflow-hidden text-lg font-medium text-cyan 
                  hover:text-white group hover:bg-gray-50" 
                 >
    <span class="absolute left-0 block w-full h-0 transition-all bg-cyan opacity-100 
    group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
    <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 
    transform translate-x-full group-hover:translate-x-0 ease">
        
    </span>
    <span class="relative">Get In Touch</span>
</Link> */}
<Link to='/getintouch' class="my-2  sm:my-4 md:my-3 lg:my-2 relative inline-flex
 items-center justify-center
 p-4 px-6 py-1 overflow-hidden font-medium text-indigo-600 transition 
 duration-300 ease-out   group"  style={{"border-left"
 : "4px solid #0496C7 "}}>
<span class="absolute inset-0 flex items-center justify-center w-full h-full
 text-white duration-300 -translate-x-full bg-cyan group-hover:translate-x-0 ease">

</span>
<span class="absolute flex items-center justify-center w-full h-full
 text-fruit transition-all duration-300 
 transform  ease text-xl">Get In Touch</span>
<span class="relative invisible">Get In Touch</span>
</Link>

            </div>
                </li>
            </ul>
        </div>
   
    </div>
    
</nav>


    </>
  )
}