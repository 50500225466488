import React from 'react'
import ourtem from '../../static/ourteamhome.webp'
export default function WhoweAre() {
  return (
    <>
          <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
     data-aos-duration="1000">Who We Are</h2>
        </div> 
      
<div className='flex flex-col lg:flex-row items-center justify-center bg-berry'data-aos="zoom-in-up"data-aos-delay="50"
     data-aos-duration="1000">
  <div className='w-full lg:w-1/2 my-10 mx-10'>
    <div class="grid grid-col-2 ">
    <div className='mx-10 thumb'>
  <img className="w-full h-auto max-h-96 rounded-lg" 
    src={ourtem} alt=""/>
</div>

  
    </div>
  </div>
  <div className='w-4.6 lg:w-1/2 my-10 mx-12 lg:mx-10 justify-end align-middle items-center appear'>
    <h1 className=' lg:text-left text-2xl text-white transition ease-in-out delay-150 
     hover:scale-y-110'>IMZI </h1>
       <p className='text-white text-sm'>Your Premier Chartered Accountants & Advisory Firm</p>
    <p className=' text-[16px] text-white text-left'>Welcome to IMZI, where our mission is to foster lasting success for our clients, people, and communities. As a network of independent audit and accounting firms, we bring a wealth of experience and local expertise to both multinational and local businesses.
      Our story is shaped by our clients and people. By actively listening to their needs, ambitions, and challenges, we craft innovative, customized solutions that empower them to thrive in an ever-evolving world. We recognize that understanding our clients' unique needs is only achievable through strong, trust-based relationships, which our dedicated team excels in building.
      At IMZI, we take pride in our cohesive and integrated team of experts who work across various disciplines to ensure the delivery of high-quality results. Our people are the cornerstone of our sustainable success, and we are dedicated to fostering exceptional leaders who collaborate to fulfill our commitments.
    </p>
  </div>
</div>
 
</>
  )
}
