import React from 'react'
import corporate from '../../../static/Corporate Finance.jpg'
export default function CorporateFinance() {
  return (
    <div>
    <div className='bg-berry'>
    <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Corporate Finance</h2>
        </div> 
<div class="p-1 items-center justify-center  ">

<div className='mx-4 my-20 justify-center items-center appear '>




<p class="mb-3 text-[16px] text-white "data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">Our Corporate Finance professionals can help you access new and innovative financing sources. Whether you want to invest in additional assets, increase your working capital, or refinance debt, we identify and help you access the right capital that will allow you to reach your business goals.

.</p>

<p class="mb-3 text-[16px] text-white my-5 font-medium"data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
Our team can help you:
.</p>

<div class="thumbanail right "data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
        <img src={corporate} alt="" />
    </div>
<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
<div class="flex flex-col pb-3">

<dd class="text-[16px] font-semibold "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Analyze current financing</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Develop financing recommendations</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Prepare financial packages</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Review, analyze, recommend and negotiate for optimal terms and conditions</dd>
</div>
<div class="flex flex-col pt-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Present to financiers</dd>



<p class="mb-3 text-[16px] text-white   my-20">There are many benefits to working with our Corporate Finance professionals, including:

.</p>

<dl class="max-w-md text-white divide-y divide-gray-200  my-5 text-reveal">
<div class="flex flex-col pb-3">

<dd class="text-[16px] font-semibold "data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Optimal debt/equity financing mix</dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Improved access to financiers </dd>
</div>
<div class="flex flex-col py-3">

<dd class="text-[16px] font-semibold"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Trusted advice from experienced corporate growth professionals</dd>
</div>
<div class="flex flex-col py-3">


</div>

</dl>
</div>
</dl>




</div>
</div>

</div>

</div>
  )
}
