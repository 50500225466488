import React from 'react'
import cor from '../../../static//in1.avif'
export default function InformationAudit() {
  return (
    <div>
                <div className='h-24  overflow-x-hidden bg-berry'>
            <h2 className='text-center text-white my-4 overline text-xl lg:text-3xl 'data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000">Information System Audit Services



</h2>
        </div> 
      <div className='flex flex-col lg:flex-row items-center justify-center bg-berry'>
<div className='w-full   '>
<div class='bg-berrry mx-2  appear'>
    <div class="my-8  mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20 ">
        <div class="thumbanail right my-8 shadow-2xl rounded-full h-auto w-auto"data-aos="zoom-out"data-aos-delay="50"
 data-aos-duration="1000">
            <img  src={cor} alt="" />
        </div>
        
        <p class="mb-3 text-white first-line:uppercase text-xs sm:text-xs md:text-[16px] lg:text-[16px]
first-line:tracking-widest first-letter:text-md sm:first-letter:text-md md:first-letter:text-lg lg:first-letter:text-2xl first-letter:font-bold 
 first-letter:text-white
 first-letter:me-1 first-letter:float-start"data-aos="zoom-in"data-aos-delay="50"
 data-aos-duration="1000"> Information System Audit Services</p>
    
        <p class='text-[16px] text-white text-left my-4 'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
        In an era dominated by digital landscapes and data-driven operations, safeguarding the integrity, confidentiality,
         and availability of your information systems is paramount. IMZI brings you specialized Information System Audit services to ensure the robustness and security of your digital infrastructure.
Protecting sensitive information is a top priority. 


        </p>
        <p class='text-[16px] text-white text-left my-2 'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
         Our audit services focus on evaluating data integrity and confidentiality measures, 
         providing you with insights to enhance encryption protocols, access controls,
          and data management practices.

Staying compliant with data protection regulations is critical in today's 
regulatory landscape. 




        </p>
        <p class='text-[16px] text-white text-left my-4 'data-aos="zoom-in-up"data-aos-delay="50"
 data-aos-duration="1000">
        We ensure that your information systems adhere to industry-specific standards, legal requirements, and best practices, mitigating the risk of regulatory breaches. Beyond security, our Information System Audit services address the performance and efficiency of your systems. We identify bottlenecks, assess scalability, and provide recommendations for optimizing system performance to support your business objectives.

Our team conducts a comprehensive analysis of technology risks associated with your information systems. From assessing the effectiveness of firewalls to evaluating the resilience of backup systems, we provide recommendations to manage and mitigate technological risks.


        </p>
    </div>
</div>

  </div>
  
 
</div>
    </div>
  )
}
